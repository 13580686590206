import classNames from 'classnames/bind';
import { Button } from 'antd';
import styles from './CareerSection.module.css';
import LINK from 'constants/link';
import useTranslation from 'next-translate/useTranslation';
const cx = classNames.bind(styles);

function CareerSection() {
  const { t: ct } = useTranslation('common');

  return (
    <section className={cx('career')}>
      <strong className={cx('title')}>{ct('join-journey')}</strong>
      <Button type="primary" shape="round">
        <a href={LINK['apply-workable']} target="_blank" rel="noreferrer">
          {ct('browse-jobs')} →
        </a>
      </Button>
    </section>
  );
}

export default CareerSection;
