
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import type { NextPage } from 'next';
import styles from 'styles/home.module.css';
import classNames from 'classnames/bind';
import BusinessSection from 'components/home/BusinessSection';
import CompanySection from 'components/home/CompanySection';
import NewsSection from 'components/home/NewsSection';
import CareerSection from 'components/home/CareerSection';
import { useRouter } from 'next/router';
const cx = classNames.bind(styles);
const Home: NextPage = () => {
    const router = useRouter();
    const { locale } = router;
    return (<div className={cx('container')}>
      <section className={cx('banner-wrapper')}>
        {/* TODO - 아기상어 10주년 이벤트 Video로 Banner Video를 임시 변경하기에 추후 video Element 제거 및 home.modules.css 정상화 - Bigdeal */}
        <video autoPlay muted loop playsInline>
          {/*
          * NextJS 에서 File을 Serving 하는것은 좋지 않다.
          * 하여 /public/videos/PC_home_01.mp4 를 S3에 올리고 -> CF - CDN 을 태우고 있다.
          * (S3 Bucket에 올라간 Object 이름은 home_babyshark.mp4)
          *
          * 추후 Banner 정상화 시 제거 - Bigdeal
          */}
          <source src="https://cf.thepinkfongcompany.com/cf/home_babyshark.mp4" type="video/mp4"/>
        </video>
      </section>
      <CompanySection />
      <BusinessSection />
      <NewsSection />
      {locale === 'ko' && <CareerSection />}
    </div>);
};
export default Home;

    async function __Next_Translate__getStaticProps__19591be6e96__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19591be6e96__ as getStaticProps }
  